import React from "react";

const Footer = () => {
  return (
    <div className="w-full flex flex-col justify-center items-center bg-red-800 text-white p-8">
      Footer
    </div>
  );
};

export default Footer;
